<template>
  <div class="mini-program-navbar">
    <div class="wrap-content">
      <div class="wrap-content__left"></div>
      <div class="wrap-content__content">
        <slot></slot>
      </div>
      <div class="wrap-content__right"></div>
    </div>

    <!-- 小程序胶囊 -->
    <div class="capsule-fixed">
      <img :src="capsuleIcon" alt="" />
    </div>
  </div>
</template>

<script setup>
// 图片
const capsuleIcon = require("@/assets/images/imitatepage/mini-program-capsule.png");
</script>

<style lang="scss" scoped>
.mobile-wrap {
  .mini-program-navbar {
    padding-top: 10px !important;

    .capsule-fixed {
      top: 10px !important;
    }
  }
}

.mini-program-navbar {
  width: 100%;
  height: 44px;
  padding-top: 25px;
  position: relative;

  .wrap-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: center;
    align-items: center;
    justify-items: center;
    box-sizing: border-box;

    &__left {
    }
    &__content {
      font-size: 18px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
    }
    &__right {
    }
  }

  .capsule-fixed {
    position: absolute;
    top: 25px;
    right: -6px;
    width: 100px;
    height: 44px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
