<template>
  <div class="attract-investment-container">
    <div class="wrap-content">
      <div class="wrap-content__header">
        <mini-program-navbar>风味项目组管理</mini-program-navbar>
      </div>

      <div class="wrap-content__body hide-scrollbar">
        <!-- 统计项 -->
        <div class="statistical-items">
          <div
            class="item"
            v-for="(item, index) in statisticalList"
            :key="'statistical' + index"
            :style="{
              backroundColor: item.backroundColor,
            }"
          >
            <div class="wrap">
              <p class="title">
                {{ item.title }}
              </p>
              <p class="number">
                {{ item.count }}
              </p>
              <div class="arrow-icon">
                <img :src="arrowIcon" alt="" />
              </div>
            </div>

            <img class="elliptic-bg" :src="item.ellipticBg" alt="" />
            <img class="img-cover-bg" :src="item.bgImage" alt="" />
          </div>
        </div>

        <!-- 报表中心 -->
        <div class="report-center">
          <div class="up-info">
            <span class="title">报表中心</span>
            <p class="text">
              <span>所负责区域/店铺合计，更新时间：2023-7-3 10:15</span>
            </p>
          </div>

          <!-- 项目组 -->
          <div class="project-team-card card-bg">
            <div
              class="card-item"
              v-for="(item, index) in projectClassList"
              :key="'card-1' + index"
            >
              <p class="count">{{ item.value }}</p>
              <p class="label">{{ item.label }}</p>
            </div>
          </div>
        </div>

        <!-- 今日营业 -->
        <div class="today-business card-bg">
          <div class="left">
            <img :src="statisticalLineIcon" alt="" />
          </div>

          <div class="right">
            <p class="title">
              <span class="name">今日营业额（元）</span>
              <img class="report-right" :src="reportRightIcon" alt="" />
            </p>
            <p class="count">987,320.00</p>
            <p class="info-1">
              较昨日增加<span class="up-bar">35,400.00</span>/减少<span
                class="down-bar"
                >35,400.00</span
              >
            </p>
            <p class="info-2">当月营业额2,312,345,787.00</p>
          </div>
        </div>

        <!-- 当前品牌数量 -->
        <div class="today-business card-bg">
          <div class="left">
            <img :src="statisticalBrandIcon" alt="" />
          </div>

          <div class="right">
            <p class="title">
              <span class="name">当前品牌数量（个）</span>
              <img class="report-right" :src="reportRightIcon" alt="" />
            </p>
            <p class="count">12465</p>
            <p class="info-1">当月营业额2,312,345,787.00</p>
          </div>
        </div>
      </div>
      <div class="footer">
        <tabbarBox :tabbarData="tabbarData"></tabbarBox>
      </div>
    </div>
  </div>
</template>

<script setup>
import miniProgramNavbar from "../mini-program-navbar/index";
import tabbarBox from "../tabbar/index.vue";

// 图片

// 统计项
const indexImgCover1 = require("@/assets/images/imitatepage/flavor-selection/index-img-cover1.png");
const indexImgCover2 = require("@/assets/images/imitatepage/flavor-selection/index-img-cover2.png");
const elliptic1 = require("@/assets/images/imitatepage/flavor-selection/index-elliptic-cover1.png");
const elliptic2 = require("@/assets/images/imitatepage/flavor-selection/index-elliptic-cover2.png");
const arrowIcon = require("@/assets/images/imitatepage/flavor-selection/index-right.png");
const reportRightIcon = require("@/assets/images/imitatepage/flavor-selection/index-report-right.png");
const statisticalLineIcon = require("@/assets/images/imitatepage/flavor-selection/index-report1.png");
const statisticalBrandIcon = require("@/assets/images/imitatepage/flavor-selection/index-report2.png");

// 统计项数据
const statisticalList = [
  {
    bgImage: indexImgCover1,
    title: "审批中心",
    count: 1,
    backroundColor: "rgba(35, 208, 154, 1)",
    ellipticBg: elliptic1,
  },
  {
    bgImage: indexImgCover2,
    title: "消息中心",
    count: 8,
    backroundColor: "rgba(86, 193, 234, 1)",
    ellipticBg: elliptic2,
  },
];

// 项目组数据
const projectClassList = [
  {
    label: "招商中项目组",
    value: 0,
  },
  {
    label: "经营中项目组",
    value: 47624,
  },
  {
    label: "待入驻项目组",
    value: 127,
  },
];

// tabbar
const tabbarData = [
  {
    icon: require("@/assets/images/imitatepage/flavor-selection/tabbar/1-001.png"),
    text: "首页",
    color: "#102f4b",
    isConvention: true,
    bold: 600,
  },
  {
    icon: require("@/assets/images/imitatepage/flavor-selection/tabbar/2-001.png"),
    text: "工作",
    color: "#999999",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/flavor-selection/tabbar/3-001.png"),
    text: "审批中心",
    color: "#999999",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/flavor-selection/tabbar/4-001.png"),
    text: "我的",
    color: "#999999",
    isConvention: true,
  },
];
</script>

<style lang="scss" scoped>
.attract-investment-container {
  position: relative;
  width: 100%;
  height: 100%;

  // 颜色
  $color1: rgba(63, 186, 136, 1);

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    padding: 20px;
    padding-bottom: 60px;
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    background: linear-gradient(
      180deg,
      #e0f1ec 10%,
      rgba(244, 247, 249, 1) 40%
    );

    &__body {
      overflow-y: auto;
      height: calc(100% - 5px);
      margin-top: 5px;
      // 卡片背景
      .card-bg {
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 3px 6px 1px rgba(0, 45, 90, 0.05);
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 10px;
        box-sizing: border-box;
      }

      // 统计项
      .statistical-items {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 15px 0;
        .item {
          position: relative;
          width: 50%;
          height: 100px;
          border-radius: 10px;
          width: calc(50% - 5px);
          padding: 10px;
          box-sizing: border-box;
          color: white;
          box-shadow: inset 0px 20px 20px 1px rgba(122, 210, 175, 0.1);
          border-radius: 10px;
          transition: box-shadow 300ms;

          &:hover {
            box-shadow: 1px 1px 10px 0px #b9b9b9;
            transition: box-shadow 300ms;
          }

          &:first-child {
            background-color: rgba(35, 208, 154, 1);
            margin-right: 5px;
          }
          &:last-child {
            background-color: rgba(86, 193, 234, 1);
            margin-left: 5px;
          }

          .wrap {
            position: relative;
            z-index: 1;
            .title {
              font-size: 18px;
              font-weight: bold;
            }
            .number {
              font-size: 22px;
              font-weight: bold;
              line-height: 45px;
            }
            .arrow-icon {
              width: 14px;
              height: 14px;
              position: absolute;
              left: 1px;
              bottom: -10px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .elliptic-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }

          .img-cover-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }

      // 报表中心
      .report-center {
        .up-info {
          padding: 10px 0;
          display: flex;
          align-items: center;
          .title {
            color: rgba(0, 0, 0, 1);
            font-size: 18px;
            font-weight: bold;
            margin-right: 6px;
          }
          .text {
            span {
              font-size: 11px;
              color: rgba(0, 0, 0, 1);
            }
          }
        }

        // 项目组
        .project-team-card {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-content: center;
          height: 110px;
          box-sizing: border-box;

          .card-item {
            width: 100%;
            height: 100%;
            > p {
              text-align: center;
            }
            .count {
              color: $color1;
              font-size: 26px;
            }
            .label {
              padding-top: 12px;
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
      }

      // 今日营业
      .today-business {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: 10px 0;
        .left {
          padding: 0 36px;
          img {
            width: 24px;
            height: 20px;
          }
        }
        .right {
          .title {
            display: flex;
            align-items: center;
            padding-top: 13px;
            .name {
              font-size: 17px;
              font-weight: bold;
            }
            .report-right {
              width: 4.5px;
              height: 7px;
            }
          }
          .count {
            color: $color1;
            font-size: 26px;
            font-weight: bold;
            padding: 15px 0;
          }
          .info-1,
          .info-2 {
            font-size: 13px;
            line-height: 25px;
          }
          .info-1 {
            .up-bar {
              color: $color1;
            }
            .down-bar {
              color: rgba(255, 103, 22, 1);
            }
          }
        }
      }
    }
  }

  // tabbar
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: 0 1px 8px 1px #e4e3e3;
  }
}
</style>
